import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Page from './report';





function App() {

  return (

    <div className="App">

      <BrowserRouter>
   


        <Routes>

          <Route path='/:id' element={<Page />} />
         


        </Routes>


      </BrowserRouter>

    </div>
  );
}

export default App;
