import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useParams } from "react-router-dom";
const Header = () => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {/* <img src="/logo.png" alt="logo" style={{ margin: '1.5rem', height: '6rem', width: '6rem' }} /> */}
      <img src="/design4.png" alt="design" style={{ width: "100%" }} />
    </div>
  );
};
const calculateMargins = (targetValue) => {
  const baseValue = 1250; // Reference value
  const incrementStep = 100; // Step size for increments
  const marginIncrement = 1.8; // Margin increment per step

  // Extreme conditions
  if (targetValue === 2500) {
    return { marginLeft: "25.5rem", marginRight: "0" };
  }

  if (targetValue === 0) {
    return { marginLeft: "0", marginRight: "23.5rem" };
  }

  if (targetValue > 2500) {
    return { marginLeft: "28rem", marginRight: "0" };
  }

  if (targetValue < 0) {
    return { marginLeft: "0", marginRight: "26rem" };
  }

  // Gradual margin calculation for values between 0 and 2500 (excluding extremes)
  const difference = targetValue - baseValue;

  if (difference > 0) {
    // For values greater than 1250, increase marginLeft only
    const marginLeft = (difference / incrementStep) * marginIncrement;
    return { marginLeft: `${marginLeft}rem`, marginRight: "0" };
  } else {
    // For values less than 1250, increase marginRight only
    const marginRight =
      (Math.abs(difference) / incrementStep) * marginIncrement;
    return { marginLeft: "0", marginRight: `${marginRight}rem` };
  }
};

const Footer = () => {
  return (
    <div>
      <img src="/design3.png" alt="design2" style={{ width: "100%" }} />
      {/* <img src="/design3.png" alt="design2" style={{ width: '91.6667%' }} /> */}
    </div>
  );
};

function Box(value, text, color) {
  return (
    <div
      style={{
        height: "2.9rem",
        width: "15rem",
        display: "flex",
        borderRadius: "0.75rem",
        borderWidth: "2px",
        alignItems: "start",
        overflow: "hidden",
        borderColor: color,
        textAlign: "center",
        borderStyle: "solid", // Ensures border style is set
        // overflow: 'visible',
      }}
    >
      <div
        style={{
          height: "2.9rem",
          width: "4rem",
          padding: 0,
          margin: 0,
          fontSize: "0.75rem",
          fontWeight: "bold",
          color: "white",
          backgroundColor: color,
          display: "flex",
          justifyContent: "center",
          paddingTop: "0.65rem",
          // alignItems: 'center',
        }}
      >
        {value}
      </div>
      <div
        style={{
          height: "2.5rem",
          width: "100%",
          // backgroundColor:"red",
          padding: 0,
          margin: 0,
          fontSize: "0.7em",
          fontWeight: "bold",
          color: "black",
          display: "flex",
          justifyContent: "center",
          // alignItems: 'center',
          marginTop: "0.65rem",
        }}
      >
        {text}
      </div>
    </div>
  );
}

const getColor = (value) => {
  if (value < 10) return "#4380b6"; // Color for 'blue'
  if (value >= 10 && value < 20) return "#ce9140"; // Color for 'golden'
  if (value >= 20) return "#d13a3d"; // Color for 'red'

  return ""; // Default or empty if no match
};
const getName = (key) => {
  const data = {
    GD1: "GD1",
    GD2: "GD2",
    GD3: "GD3",
    "Ach d": "House cricket",
    "Aci spp.": "Caviar",
    "Act d": "Kiwi",
    "Ada d": "Baobab",
    Aga: "Agar Agar",
    "Aga b": "White mushroom",
    "All a": "Shallot",
    "All c": "Onion",
    "All p": "Leek",
    "All s": "Garlic",
    "All sc": "Chives",
    "All u": "Wild garlic",
    "Alo v": "Aloe",
    "Ama t": "Amaranth",
    "Ame a": "Tea, black",
    "Ana c": "Pineapple",
    "Ana o": "Cashew",
    "Ana p": "Duck",
    "Ane g": "Dill",
    "Ang a": "Eel",
    "Api g": "Celery Bulb",
    "Api g_stalk": "Celery Stalk",
    "Api m_honey": "Honey",
    "Ara h": "Peanut",
    "Arc l": "Greater burdock root",
    "Arm r": "Horseradish",
    "Aro spp.": "Aronia",
    "Art d": "Tarragon",
    "Asp n": "Aspergillus niger",
    "Asp o": "White asparagus",
    "Ast a": "Noble crayfish",
    "Ave s": "Oat",
    "Bam v": "Bamboo sprouts",
    "Ber e": "Brazil nut",
    "Bet vu": "Chard",
    "Bet vul": "Red beet",
    "Bol e": "Boletus",
    "Bos d 4": "Cow's milk Bos d 4 * (Alpha-Lactalbumin)",
    "Bos d 5": "Cow's milk Bos d 5 * (Beta-Lactoglobulin)",
    "Bos d 8": "Cow's milk Bos d 8 * (Casein)",
    "Bos d_but": "Buttermilk",
    "Bos d_Ca": "Camembert",
    "Bos d_Em": "Emmental",
    "Bos d_Go": "Gouda",
    "Bos d_Hue": "Cottage cheese",
    "Bos d_meat": "Beef",
    "Bos d_milk": "Cow's milk",
    "Bos d_Mo": "Mozzarella",
    "Bos d_Pa": "Parmesan",
    "Bos d_veal_meat": "Veal",
    "Bra n": "Rapeseed",
    "Bra o": "Cabbage",
    "Bra o_bot": "Cauliflower",
    "Bra o_cap": "White cabbage",
    "Bra o_gem": "Brussels sprouts",
    "Bra o_gon": "Kohlrabi",
    "Bra o_ita": "Broccoli",
    "Bra o_rom": "Romanesco",
    "Bra o_rub": "Red cabbage",
    "Bra o_sab": "Green cabbage",
    "Bra o_saba": "Savoy",
    "Bra r": "Turnip",
    "Bra ra_chi": "Pok-Choi",
    "Bra ra_pek": "Chinese cabbage",
    "Bub b_milk": "Buffalo milk",
    "Cam d": "Camel milk",
    "Cam s": "Tea, green",
    "Can c": "Chanterelle",
    "Can s_seed": "Hempseed",
    "Cap a": "Paprika",
    "Cap c": "Venison",
    "Cap f": "Cayenne pepper",
    "Cap f_chi": "Chili (red)",
    "Cap h_cheese": "Goat cheese",
    "Cap h_meat": "Goat",
    "Cap h_milk": "Goat milk",
    "Cap s": "Caper",
    "Car c": "Caraway",
    "Car i": "Pecan nut",
    "Car p": "Papaya",
    "Car spp.": "Cockle",
    "Car t": "Safflower oil",
    "Cas s": "Sweet chestnut",
    "Cer e": "Stag",
    "Che q": "Quinoa",
    "Chi spp.": "Crab",
    "Chl spp.": "Chlorella",
    "Cic a": "Chickpea",
    "Cic e": "Endive",
    "Cic if": "Chicorée",
    "Cic i_fol": "Radicchio",
    "Cin v": "Cinnamon",
    "Cit a": "Lime",
    "Cit l": "Lemon",
    "Cit la": "Watermelon",
    "Cit p": "Grapefruit",
    "Cit r": "Tangerine",
    "Cit s": "Orange",
    "Clu h": "Atlantic herring",
    "Coc n": "Coconut milk",
    "Coc n_nut": "Coconut",
    "Cof a": "Coffee",
    Col_spp: "Kola nut",
    "Con spp.": "Curry",
    "Cor a_hazel": "Hazelnut",
    "Cor s": "Coriander",
    "Cot c": "Quail egg",
    "Cuc m": "Melon",
    "Cuc met": "Kiwano",
    "Cuc m_butternut": "Pumpkin Butternut",
    "Cuc m_hoc": "Pumpkin Hokkaido",
    "Cuc p": "Pumpkin seed",
    "Cuc pe": "Zucchini",
    "Cuc s": "Cucumber",
    "Cum c": "Cumin",
    "Cur l": "Turmeric",
    "Cym c": "Lemongrass",
    "Cyn c": "Artichoke",
    "Cyp c": "Carp",
    "Cyp e": "Tigernut",
    "Dau c": "Carrot",
    "Ele c": "Cardamom",
    "Eng e": "European anchovy",
    "Equ c_meat": "Horse",
    "Eru s": "Arugula",
    "Eso l": "Northern pike",
    "Fag e": "Buckwheat",
    "Fic c": "Fig",
    "Fla v": "Enoki",
    "Foe v": "Fennel (bulb)",
    "Fra a": "Strawberry",
    "Gad m": "Atlantic cod",
    "Gal d_meat": "Chicken",
    "Gal d_white": "Egg white",
    "Gal d_yolk": "Egg yolk",
    "Gin b": "Ginkgo",
    "Gly m": "Soy",
    "Hal g": "Abalone",
    "Hel a": "Sunflower",
    "Hib s": "Hibiscus",
    "Hom g": "Lobster",
    "Hom s LF": "Human Lactoferrin",
    "Hor v": "Barley",
    "Hor v_malt": "Malt (barley)",
    "Hum l": "Hops",
    IgG_Std1: "IgG_Std1",
    IgG_Std2: "IgG_Std2",
    IgG_Std3: "IgG_Std3",
    IgG_Std4: "IgG_Std4",
    IgG_Std5: "IgG_Std5",
    "Ipo b": "Sweet potato",
    "Jas o": "Jasmine",
    "Jug r_nut": "Walnut",
    "Jun c": "Juniper berry",
    "Lau n": "Bay leaf",
    "Len c": "Lentil",
    "Lep m": "Maca root",
    "Lep s": "Watercress",
    "Lin u": "Linseed",
    "Lit c": "Lychee",
    "Lit s": "Shrimp mix",
    "Loc m": "Migratory locust",
    "Lol spp.": "Squid",
    "Lop p": "Monkfish",
    "Lup a": "Lupine seed",
    "Mac inte": "Macadamia",
    "Mal d": "Apple",
    "Man e": "Tapioca",
    "Man i": "Mango",
    "Mat c": "Chamomile",
    "Mel a": "Haddock",
    "Mel g": "Turkey",
    "Men p": "Peppermint",
    "Mer c": "Hake",
    "Mor o": "Moringa",
    "Mor spp._berry": "Mulberry",
    "Mus a": "Banana",
    "Myr f": "Nutmeg",
    "Myt e": "Common mussel",
    "Nep c": "Mint",
    "Oci b": "Basil",
    "Oct v": "Octopus",
    Ole_fruit: "Olive",
    "Onc m": "Trout",
    "Ori m": "Majoram",
    "Ori v": "Oregano",
    "Ory s": "Rice",
    Ory_meat: "Rabbit",
    "Ost e": "Oyster",
    "Ovi a_cheese": "Sheep cheese",
    "Ovi a_meat": "Lamb",
    "Ovi a_milk": "Sheep milk",
    "Pan b": "Northern prawn",
    "Pan g": "Ginseng",
    "Pan m": "Millet",
    "Pap s": "Poppyseed",
    "Pas e": "Passion fruit",
    "Pas s": "Parsnip",
    "Pau c": "Guarana",
    "Pec spp.": "Scallop",
    "Pers a": "Avocado",
    "Pet c": "Parsley",
    "Pha spp.": "Razor shell",
    "Pha v": "White bean",
    "Pha v_green": "Green bean",
    "Pho d_fruit": "Date",
    "Phy p": "Physalis",
    "Pim a": "Anise",
    "Pin k": "Pine nut",
    "Pip n": "Pepper ⚫⚪🟢🔴🟡",
    "Pis s": "Pea",
    "Pis s_conv": "Sugar pea",
    "Pis v": "Pistachio",
    "Ple e": "French horn mushroom",
    "Ple o": "Oyster mushroom",
    "Ple p": "European plaice",
    "Pru ar": "Apricot",
    "Pru av": "Cherry",
    "Pru do": "Plum",
    "Pru du": "Almond",
    "Pru du_milk": "Almond milk",
    "Pru p": "Peach",
    "Pru p_nuc": "Nectarine",
    "Pun g": "Pomegranate",
    "Pyr c": "Pear",
    "Pyr y": "Nori",
    "Raj c": "Thornback Ray",
    "Rap s": "Radish",
    "Rib g": "Gooseberry",
    "Rib r": "Red currant",
    "Ros o": "Rosmary",
    "Rub f": "Blackberry",
    "Rub i": "Raspberry",
    "Rud spp.": "Venus clam",
    "Sac c": "Baker's yeast",
    "Sac o": "Cane sugar",
    "Sac u": "Brewer's yeast",
    "Sal h": "Chia seed",
    "Sal o": "Sage",
    "Sal s": "Salmon",
    "Sam f": "Elderflower",
    "Sam n": "Elderberry",
    "Sar p": "European pilchard",
    "Sco m": "Turbot",
    "Sco s": "Mackerel",
    "Seb m": "Atlantic redfish",
    "Sec c_flour": "Rye",
    "Sep spp.": "Sepia",
    "Ses i": "Sesame",
    Sin: "Mustard",
    "Sma s": "Yacón root",
    "Sol m": "Eggplant",
    "Sol s": "Sole",
    "Sol t": "Potato",
    "Sola l": "Tomato",
    "Spa a": "Gilt-head bream",
    "Spi o": "Spinach",
    "Spi spp.": "Spirulina",
    "Str c": "Ostrich",
    "Str m": "M-Transglutaminase, meat glue",
    "Sus d_meat": "Pork",
    "Sus s_meat": "Boar",
    "Syz a": "Clove",
    "Tam i": "Tamarind",
    "Tar v": "Dandelion root",
    "Ten m": "Mealworm",
    "The c": "Cocoa",
    "Thu a": "Tuna",
    "Thy v": "Thyme",
    "Tri a": "Wheat",
    "Tri a Gliadin": "Wheat gliadin",
    "Tri ae": "Gluten",
    "Tri a_bran": "Wheat bran",
    "Tri a_grass": "Wheatgrass",
    "Tri d": "Emmer",
    "Tri du": "Durum",
    "Tri fo": "Fenugreek",
    "Tri m": "Einkorn",
    "Tri p": "Polish wheat",
    "Tri s": "Spelt",
    "Und p": "Wakame",
    "Urt d_leaf": "Nettle leaves",
    "Vac m": "Blueberry",
    "Vac m_cra": "Cranberry",
    "Val l": "Lamb's lettuce",
    "Van p": "Vanilla",
    "Vig r": "Mung bean",
    "Vit v": "Grape",
    "Vit v_ros": "Raisin",
    "Xip g": "Swordfish",
    "Zea m": "Corn",
    "Zin o": "Ginger",
  };

  const value = data[key];
  return value;
};

// const categories = () => {
const categories = {
  "Fish & Seafood": [
    "Abalone",
    "Atlantic cod",
    "Atlantic herring",
    "Atlantic redfish",
    "Carp",
    "Caviar",
    "Cockle",
    "Common mussel",
    "Crab",
    "Eel",
    "European anchovy",
    "European pilchard",
    "European plaice",
    "Gilt-head bream",
    "Haddock",
    "Hake",
    "Lobster",
    "Mackerel",
    "Monkfish",
    "Noble crayfish",
    "Northern pike",
    "Northern prawn",
    "Oyster",
    "Octopus",
    "Razor shell",
    "Salmon",
    "Scallop",
    "Sepia",
    "Shrimp mix",
    "Sole",
    "Squid",
    "Swordfish",
    "Thornback Ray",
    "Trout",
    "Tuna",
    "Turbot",
    "Venus clam",
  ],

  "Novel Foods": [
    "Almond milk",
    "Aloe",
    "Aronia",
    "Baobab",
    "Chia seed",
    "Chlorella",
    "Dandelion root",
    "Ginkgo",
    "Ginseng",
    "Greater burdock root",
    "Guarana",
    "House cricket",
    "Maca root",
    "Mealworm",
    "Migratory locust",
    "Nori",
    "Safflower oil",
    "Spirulina",
    "Tapioca",
    "Wakame",
    "Yacón root",
  ],

  Nuts: [
    "Almond",
    "Brazil nut",
    "Cashew",
    "Coconut",
    "Coconut milk",
    "Hazelnut",
    "Kola nut",
    "Macadamia",
    "Pecan nut",
    "Pistachio",
    "Sweet chestnut",
    "Tigernut",
    "Walnut",
  ],

  Others: [
    "Agar Agar",
    "Aspergillus niger",
    "Baker's yeast",
    "Brewer's yeast",
    "Cane sugar",
    "Elderflower",
    "Honey",
    "Hops",
    "M-Transglutaminase, meat glue",
  ],

  "Cereals & Seeds": [
    "Amaranth",
    "Barley",
    "Buckwheat",
    "Corn",
    "Durum",
    "Einkorn",
    "Emmer",
    "Gluten",
    "Hempseed",
    "Linseed",
    "Lupine seed",
    "Malt (barley)",
    "Millet",
    "Oat",
    "Pine nut",
    "Polish wheat",
    "Poppyseed",
    "Pumpkin seed",
    "Quinoa",
    "Rapeseed",
    "Rice",
    "Rye",
    "Sesame",
    "Spelt",
    "Sunflower",
    "Wheat",
    "Wheat bran",
    "Wheat gliadin",
    "Wheatgrass",
  ],

  Spices: [
    "Anise",
    "Basil",
    "Bay leaf",
    "Caraway",
    "Cardamom",
    "Cayenne pepper",
    "Chili (red)",
    "Cinnamon",
    "Clove",
    "Coriander",
    "Mustard",
    "Cumin",
    "Curry",
    "Dill",
    "Fenugreek",
    "Ginger",
    "Juniper berry",
    "Lemongrass",
    "Majoram",
    "Mint",
    "Nutmeg",
    "Oregano",
    "Paprika",
    "Parsley",
    "Pepper ⚫⚪🟢🔴🟡",
    "Rosmary",
    "Sage",
    "Tarragon",
    "Thyme",
    "Turmeric",
    "Vanilla",
  ],

  Fruits: [
    "Apple",
    "Apricot",
    "Banana",
    "Blackberry",
    "Blueberry",
    "Cherry",
    "Cranberry",
    "Date",
    "Elderberry",
    "Fig",
    "Grape",
    "Grapefruit",
    "Gooseberry",
    "Kiwi",
    "Lemon",
    "Lime",
    "Lychee",
    "Mango",
    "Melon",
    "Mulberry",
    "Nectarine",
    "Orange",
    "Papaya",
    "Passion fruit",
    "Peach",
    "Pear",
    "Physalis",
    "Pineapple",
    "Plum",
    "Pomegranate",
    "Raisin",
    "Raspberry",
    "Red currant",
    "Strawberry",
    "Tangerine",
    "Watermelon",
  ],

  Vegetables: [
    "Artichoke",
    "Arugula",
    "Asparagus",
    "Avocado",
    "Bamboo sprouts",
    "Broccoli",
    "Brussels sprouts",
    "Cabbage",
    "Caper",
    "Carrot",
    "Cauliflower",
    "Celery Bulb",
    "Celery Stalk",
    "Chard",
    "Chicorée",
    "Chinese cabbage",
    "Chives",
    "Cucumber",
    "Eggplant",
    "Endive",
    "Fennel (bulb)",
    "Green bean",
    "Green cabbage",
    "Horseradish",
    "Kiwano",
    "Kohlrabi",
    "Lamb's lettuce",
    "Leek",
    "Nettle leaves",
    "Olive",
    "Onion",
    "Parsnip",
    "Pea",
    "Pok-Choi",
    "Pumpkin Butternut",
    "Pumpkin Hokkaido",
    "Radicchio",
    "Radish",
    "Red beet",
    "Red cabbage",
    "Romanesco",
    "Savoy",
    "Shallot",
    "Spinach",
    "Sweet potato",
    "Tomato",
    "Turnip",
    "Watercress",
    "White cabbage",
    "Wild garlic",
    "Zucchini",
  ],

  Meat: [
    "Beef",
    "Boar",
    "Chicken",
    "Duck",
    "Goat",
    "Horse",
    "Lamb",
    "Ostrich",
    "Pork",
    "Rabbit",
    "Stag",
    "Turkey",
    "Veal",
    "Venison",
  ],

  "Herbal Tees & Coffee": [
    "Chamomile",
    "Cocoa",
    "Coffee",
    "Hibiscus",
    "Jasmine",
    "Moringa",
    "Peppermint",
    "Tea, black",
    "Tea, green",
  ],

  "Edible Mushrooms": [
    "Boletus",
    "Chanterelle",
    "Enoki",
    "French horn mushroom",
    "Oyster mushroom",
    "White mushroom",
  ],

  "Dairy Products & Egg": [
    "Buffalo milk",
    "Buttermilk",
    "Camel milk",
    "Camembert",
    "Cottage cheese",
    "Cow's milk",
    "Cow's milk Bos d 4 * (Alpha-Lactalbumin)",
    "Cow's milk Bos d 5 * (Beta-Lactoglobulin)",
    "Cow's milk Bos d 8 * (Casein)",
    "Egg white",
    "Egg yolk",
    "Emmental",
    "Goat cheese",
    "Goat milk",
    "Gouda",
    "Mozzarella",
    "Parmesan",
    "Quail egg",
    "Sheep cheese",
    "Sheep milk",
  ],

  Legumes: [
    "Chickpea",
    "Green bean",
    "Lentil",
    "Mung bean",
    "Pea",
    "Peanut",
    "Soy",
    "Sugar pea",
    "Tamarind",
    "White bean",
  ],
  CCD: ["Human Lactoferrin"],
};

// }

const Page = () => {
  const [myResponse, setMyResponse] = useState({});
  const [myResult, setMyResult] = useState([]);
  const [totalScore, setTotalScore] = useState(0);
  const [redBoxes, setRedBoxes] = useState(0);
  const [yellowBoxes, setYellowBoxes] = useState(0);
  const [fishBoxes, setFishBoxes] = useState([]);
  const [vegetableBoxes, setVegetableBoxes] = useState([]);
  const [margins, setMargins] = useState({});
  const { id } = useParams();

  useEffect(() => {
    // Calculate the count of data values greater than 10
    const totalCount = myResult.filter((data) => data.value > 10).length;
    const totalCount2 = myResult.filter(
      (data) => data.value >= 5 && data.value <= 9
    ).length;
    const fishAndSeafoodItems = Array.isArray(myResult)
      ? myResult.filter((data) =>
          (categories["Fish & Seafood"] || []).includes(getName(data.name))
        )
      : [];
    const vegetable = Array.isArray(myResult)
      ? myResult.filter((data) =>
          (categories["Vegetables"] || []).includes(getName(data.name))
        )
      : [];
    console.log("Filtered Fish & Seafood Items:", fishAndSeafoodItems);

    setRedBoxes(totalCount);
    setYellowBoxes(totalCount2);
    setFishBoxes(fishAndSeafoodItems);
    setVegetableBoxes(vegetable);
    // console.log(totalCount);
    // console.log(totalCount2);
  }, [myResult]);

  useEffect(() => {
    const getdata = async () => {
      // alert(id)
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      const urlencoded = new URLSearchParams();
      urlencoded.append("_id", id);
      // urlencoded.append("_id", "3310246537770");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          "http://localhost:1337/viewwholereport",
          requestOptions
        );
        // const response = await fetch("https://yourgutmap-food-sensitivity-423a2af84621.herokuapp.com/viewwholereport", requestOptions);
        const result = await response.json();
        // alert(response);
        setMyResponse(result);
        setMyResult(result.result);

        // download();
      } catch (error) {
        console.log("error", error);
      }
    };

    getdata();
  }, []); // Empty dependency array ensures it runs only once on mount

  useEffect(() => {
    // Calculate the sum of rounded values in myResult array
    const sum = myResult.reduce((accumulator, currentValue) => {
      return accumulator + Math.round(currentValue.value);
    }, 0);

    // Only update state if the sum has changed to avoid unnecessary re-renders
    if (sum !== totalScore) {
      setTotalScore(sum);
    }
    setTotalScore(1550);
    const newMargin = calculateMargins(totalScore);
    setMargins(newMargin);
  }, [myResult, totalScore]); // The effect depends on both myResult and totalScore

  // useEffect(() => {
  //   const sum = myResult.reduce((accumulator, currentValue) => {
  //     return accumulator + Math.round(currentValue.value);
  //   }, 0);
  //   setTotalScore(sum);
  // }, [myResult]);

  // const download = async () => {
  //   // Hide the button by adding the class
  //   const button = document.getElementById('downloadButton');
  //   if (button) button.classList.add('hide-for-pdf');

  //   const pdf = new jsPDF();
  //   const pages = document.querySelectorAll('.page1');

  //   for (let i = 0; i < pages.length; i++) {
  //     const canvas = await html2canvas(pages[i], { scale: 1.1 });
  //     const imgData = canvas.toDataURL('image/png');

  //     if (i > 0) {
  //       pdf.addPage();
  //     }

  //     const pageWidth = pdf.internal.pageSize.getWidth();
  //     const pageHeight = pdf.internal.pageSize.getHeight();
  //     const imgWidth = 210;
  //     const imgHeight = 297;

  //     pdf.addImage(imgData, 'PNG', (pageWidth - imgWidth) / 2, (pageHeight - imgHeight) / 2, imgWidth, imgHeight);
  //   }

  //   // Save the PDF
  //   pdf.save('fs-report.pdf');

  //   // Show the button again by removing the class
  //   if (button) button.classList.remove('hide-for-pdf');
  // }
  // const download = async () => {
  //   // Hide the button by adding the class
  //   const button = document.getElementById('downloadButton');
  //   if (button) button.classList.add('hide-for-pdf');

  //   const pdf = new jsPDF();
  //   const pages = document.querySelectorAll('.page1');

  //   for (let i = 0; i < pages.length; i++) {
  //     // Increase the scale for high-quality rendering
  //     const canvas = await html2canvas(pages[i], { scale: 2 }); // Higher scale for better quality

  //     // Use JPEG format with high quality (0.9 to 1.0)
  //     const imgData = canvas.toDataURL('image/jpeg', 0.9); // JPEG format with 90% quality

  //     if (i > 0) {
  //       pdf.addPage();
  //     }

  //     const pageWidth = pdf.internal.pageSize.getWidth();
  //     const pageHeight = pdf.internal.pageSize.getHeight();
  //     const imgWidth = pageWidth;
  //     const imgHeight = (canvas.height * pageWidth) / canvas.width; // Maintain aspect ratio

  //     // Add the image to PDF with optimized compression
  //     pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight, undefined, 'MEDIUM');
  //   }

  //   // Save the PDF with a specified filename
  //   pdf.save('food-sensitivity-report.pdf');
  //  // Close the current tab
  //  setTimeout(() => {
  //   window.close();
  // }, 1000); // Allow time for the download dialog to appear
  //   // Show the button again by removing the class
  //   // if (button) button.classList.remove('hide-for-pdf');
  // };

  const download = async () => {
    // Hide the button by adding the class
    // const button = document.getElementById('downloadButton');
    // if (button) button.classList.add('hide-for-pdf');

    try {
      const pdf = new jsPDF();
      const pages = document.querySelectorAll(".page1");

      for (let i = 0; i < pages.length; i++) {
        const canvas = await html2canvas(pages[i], { scale: 2 }); // Higher scale for better quality
        const imgData = canvas.toDataURL("image/jpeg", 0.9); // JPEG format with 90% quality

        if (i > 0) {
          pdf.addPage();
        }

        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const imgWidth = pageWidth;
        const imgHeight = (canvas.height * pageWidth) / canvas.width; // Maintain aspect ratio

        pdf.addImage(
          imgData,
          "JPEG",
          0,
          0,
          imgWidth,
          imgHeight,
          undefined,
          "MEDIUM"
        );
      }

      // Save the PDF with a specified filename
      pdf.save(`food-sensitivity-report ${myResponse.kitid}.pdf`);

      // Redirect to localhost:5000 after a short delay
      // setTimeout(() => {
      //   window.location.href = 'http://localhost:5000';5000
      // }, 10);
      // window.close();
      // console.log("hammad")
    } catch (error) {
      // Adjust delay as necessary
      // window.location.href = 'http://localhost:5000';
      window.close();
      console.log("saad");
    }
  };

  return (
    <div
      className="page-container"
      style={{ width: "100%", display: "flex", justifyContent: "center" }}
    >
      <div id="ali">
        {/* <button id='downloadButton' onClick={download} className='font-bold mt-3 text-2xl'>Download</button> */}
        <button
          id="downloadButton"
          onClick={download}
          style={{
            fontWeight: "bold",
            marginTop: "0.75rem",
            backgroundColor: "#61dafb",
            fontSize: "1.5rem",
            position: "absolute",
            left: "35%",
            top: "207mm",
            padding: " 20px 200px",
            border: "none",
            borderRadius: "10px",
            cursor: "pointer",
            zIndex: "100",
          }}
        >
          Download
        </button>

        {/* page 1 */}
        {/*  flex flex-col justify-between */}
        <div
          className="page1"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "297mm",
            width: "210mm",
          }}
        >
          {/* <Header /> */}
          {/* {Header()} */}

          {/* <div className="flex-grow flex-col flex justify-center items-center"> */}
          <div className="container">
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                src="/logo192.png"
                alt="logo2"
                style={{
                  width: "25%",
                  marginBottom: "0.75rem",
                }}
              />
              <div
                style={{
                  width: "0.5rem",
                }}
              ></div>
              <h1
                className="purple"
                style={{
                  marginTop: "3rem",
                  fontSize: "3.75rem",
                  fontWeight: "bold",
                }}
              >
                Food Sensitivity
              </h1>
            </div>
            <div
              style={{
                height: "3rem",
              }}
            ></div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "1.25rem",
                }}
              >
                <h3 className="myblue extrabold-text">Patient Name:</h3>
                <h3 className="myblue extrabold-text">Sample ID:</h3>
                <h3 className="myblue extrabold-text">Sample Date:</h3>
              </div>
              <div
                style={{
                  width: "3rem",
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "1.25rem",
                }}
              >
                <h3 className="purple extrabold-text">
                  {myResponse.patientName == null
                    ? "---"
                    : myResponse.patientName}
                </h3>
                <h3 className="purple extrabold-text">
                  {myResponse.kitid == null ? "---" : myResponse.kitid}
                </h3>
                <h3 className="purple extrabold-text">
                  {myResponse.SampleDate == null
                    ? "---"
                    : myResponse.SampleDate}
                </h3>
              </div>
            </div>
          </div>
          {/* <Footer /> */}
          {/* {Footer()} */}
        </div>

        {/* page 2 */}
        <div
          className="page1 flex flex-col justify-between"
          style={{ height: "297mm", width: "210mm" }}
        >
          {/* <Header /> */}
          {/* {Header()} */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              alignItems: "center",
            }}
          >
            <img
              src="/logo.png"
              alt="logo2"
              style={{
                width: "6rem", // 24 * 0.25rem = 6rem
                marginBottom: "0.4rem",
                marginTop: "0.4rem", // 3 * 0.25rem = 0.75rem
              }}
            />
            <div
              style={{
                width: "0.5rem", // 2 * 0.25rem = 0.5rem
              }}
            ></div>
            <h1
              className="purple"
              style={{
                fontSize: "1.25rem", // Equivalent to text-xl in Tailwind (1.25rem)
                fontWeight: "700", // Equivalent to font-bold in Tailwind (700)
              }}
            >
              Food Sensitivity
            </h1>
            <div
              style={{
                display: "flex",
                width: "100%", // Equivalent to w-full (100% width)
                marginTop: "1rem", // Equivalent to mt-3 (3 * 0.25rem = 0.75rem)
                marginLeft: "2.25rem", // Equivalent to ml-9 (9 * 0.25rem = 2.25rem)
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "0.75rem", // Equivalent to mb-3 (3 * 0.25rem = 0.75rem)
                }}
              >
                <h3 className="myblue extrabold-text2">Patient Name:</h3>
                <h3 className="myblue extrabold-text2">Sample ID:</h3>
                <h3 className="myblue extrabold-text2">Sample Date:</h3>
              </div>
              <div
                style={{
                  width: "3rem", // 12 * 0.25rem = 3rem
                }}
              ></div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "0.75rem", // Equivalent to mb-3 (3 * 0.25rem = 0.75rem)
                }}
              >
                <h3 className="purple extrabold-text2">
                  {myResponse.patientName == null
                    ? "---"
                    : myResponse.patientName}
                </h3>
                <h3 className="purple extrabold-text2">
                  {myResponse.kitid == null ? "---" : myResponse.kitid}
                </h3>
                <h3 className="purple extrabold-text2">
                  {myResponse.SampleDate == null
                    ? "---"
                    : myResponse.SampleDate}
                </h3>
              </div>
            </div>
            <div
              style={{
                backgroundColor: "#19b0e6", // Equivalent to bg-[#19b0e6]
                width: "100%", // Equivalent to w-full
                padding: "1.5rem", // Equivalent to p-6 (6 * 0.25rem = 1.5rem)
                color: "white", // Equivalent to text-white
              }}
            >
              <h4
                style={{
                  fontWeight: "bold",
                }}
              >
                Total IgG Immune Load
              </h4>
              <p
                style={{
                  fontSize: "0.875rem", // Equivalent to text-sm (0.875rem)
                }}
              >
                Your total IgG reactivity shows the current immune load your
                body is experiencing with your current diet. Research shows by
                following your IgG guided dietary changes, total immune load can
                be reduced.
              </p>
              <div
                style={{
                  width: "100%", // Equivalent to w-full
                  height: "13rem", // Equivalent to h-52 (52 * 0.25rem = 13rem)
                  backgroundColor: "white", // Equivalent to bg-white
                  marginTop: "1.5rem", // Equivalent to mt-6 (6 * 0.25rem = 1.5rem)
                  display: "flex", // Equivalent to flex
                  flexDirection: "column", // Equivalent to flex-col
                  justifyContent: "center", // Equivalent to justify-center
                  alignItems: "center", // Equivalent to items-center
                }}
              >
                <div
                  style={{
                    marginBottom: "0.25rem",
                    // marginLeft: '0rem',
                    // marginRight:"0rem",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "0.5rem",
                    position: "relative",
                    ...margins,
                  }}
                >
                  <p
                    style={{
                      fontSize: "0.75rem",
                      color: "#19b0e6",
                    }}
                  >
                    Your immune load
                  </p>
                  <div
                    style={{
                      position: "relative",
                      marginBottom: "0.25rem",
                    }}
                  >
                    <img
                      style={{
                        height: "2.5rem",
                        width: "2.5rem",
                      }}
                      src="/plus-01.png"
                      alt="image"
                    />
                    <span
                      style={{
                        marginTop: "0.3rem",
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        color: "white",
                        fontWeight: "bold",
                        fontSize: "0.6875rem",
                        paddingTop: "0.5rem",
                      }}
                    >
                      {totalScore}
                      {/* 2% */}
                    </span>
                  </div>
                  <div
                    style={{
                      width: "0", // Equivalent to w-0
                      height: "0", // Equivalent to h-0
                      borderLeft: "15px solid transparent", // Equivalent to border-l-[15px]
                      borderRight: "15px solid transparent", // Equivalent to border-r-[15px]
                      borderTop: "20px solid #19b0e6", // Equivalent to border-t-[20px] border-t-[#19b0e6]
                    }}
                  ></div>
                </div>

                {/* <img src="bar.png" className='w-2/3' alt="" /> */}
                <img
                  src="bar.png"
                  alt=""
                  style={{
                    width: "66.6667%", // Equivalent to w-2/3
                  }}
                />

                {/* <div className="text-sm font-bold w-5/6 rounded-full h-11 bg-gradient-to-r from-[#7fc5c6] via-[#dac37f] to-[#ee9672]"></div> */}
                {/* <div
                className="absolute -top-1 left-0 transform w-0 h-0 border-l-8 border-r-8 border-t-8 border-transparent rounded-full border-t-purple-800"
                style={{ left: 270 }}
                ></div> */}
                <div
                  style={{
                    display: "flex",
                    gap: "18rem", // Equivalent to gap-x-72 (72 * 0.25rem = 18rem)
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "0",
                        height: "0",
                        marginLeft: "6rem", // Equivalent to ml-24 (24 * 0.25rem = 6rem)
                        borderLeft: "15px solid transparent",
                        borderRight: "15px solid transparent",
                        borderBottom: "20px solid #19b0e6", // Equivalent to border-b-[#19b0e6]
                      }}
                    />
                    <p
                      style={{
                        marginLeft: "5rem", // Equivalent to ml-12 (12 * 0.25rem = 3rem)
                        fontSize: "0.75rem", // Equivalent to text-xs
                        color: "#19b0e6", // Equivalent to text-[#19b0e6]
                      }}
                    >
                      raised immune load
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div
                      style={{
                        width: "0",
                        height: "0",
                        marginLeft: "-5rem", // Equivalent to ml-12 (12 * 0.25rem = 3rem)
                        borderLeft: "15px solid transparent",
                        borderRight: "15px solid transparent",
                        borderBottom: "20px solid #19b0e6", // Equivalent to border-b-[#19b0e6]
                      }}
                    />
                    <p
                      style={{
                        marginBottom: "0.25rem", // Equivalent to mb-1 (1 * 0.25rem = 0.25rem)
                        marginRight: "4.5rem", // Equivalent to mr-10 (10 * 0.25rem = 2.5rem)
                        fontSize: "0.75rem", // Equivalent to text-xs
                        color: "#19b0e6", // Equivalent to text-[#19b0e6]
                      }}
                    >
                      high immune load
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p
              style={{
                color: "#19b0e6", // Equivalent to class "purple"
                marginTop: "1px", // Equivalent to mt-[1px]
                marginLeft: "2.5rem", // Equivalent to mx-10 (10 * 0.25rem = 2.5rem)
                marginRight: "2.5rem",
                fontSize: "0.75rem", // Equivalent to text-xs
              }}
            >
              Your food specific IgG antibody diet guide shows IgG reactions and
              cross reactions to foods and drinks.
            </p>
            <p
              style={{
                color: "#19b0e6", // Equivalent to class "purple"
                marginTop: "1px", // Equivalent to mt-[1px]
                marginLeft: "2.5rem", // Equivalent to mx-10
                marginRight: "2.5rem",
                fontSize: "0.75rem", // Equivalent to text-xs
              }}
            >
              This information can be used by a qualified healthcare
              practitioner to adjust your diet. Any changes in diet including
              the removal of foods should be overseen by a qualified
              professional, to prevent nutrient deficiencies.
            </p>
            <p
              style={{
                color: "#19b0e6", // Equivalent to class "purple"
                marginTop: "1px", // Equivalent to mt-[1px]
                marginLeft: "2.5rem", // Equivalent to mx-10
                marginRight: "2.5rem",
                fontSize: "0.75rem", // Equivalent to text-xs
              }}
            >
              Please note: a food specific IgG antibody test does not analyse
              classical allergies, where IgE antibodies are involved. Food
              specific IgG antibody testing cannot diagnose conditions such as
              Coeliac Disease, or Lactose Intolerance. Any pre existing
              allergies or negative reactions to foods should be discussed with
              your health practitioner. YourGutMap defines Food Sensitivity as a
              food specific IgG antibody reaction. This is not a diagnostic test
            </p>
          </div>
          {/* <Footer /> */}
          {/* {Footer()} */}
        </div>

        {/* page 3۔1.1*/}
        <div
          className="page1"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "297mm",
            width: "210mm",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "1.75rem",
              paddingRight: "1.75rem",
              marginTop: "0.75rem",
            }}
          >
            <h3
              className="myblue"
              style={{
                fontWeight: "800",
                marginBottom: "0.25rem",
                fontSize: "1rem",
              }}
            >
              Patient Name
            </h3>
            <h3
              className="myblue"
              style={{
                fontWeight: "800",
                marginBottom: "0.25rem",
                fontSize: "1rem",
              }}
            >
              Sample ID
            </h3>
            <h3
              className="myblue"
              style={{
                fontWeight: "800",
                marginBottom: "0.25rem",
                fontSize: "1rem",
              }}
            >
              Sample Date
            </h3>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: "1.75rem",
              paddingRight: "1.75rem",
            }}
          >
            <h3
              style={{
                color: "#19b0e6",
                fontWeight: "800",
                marginBottom: "1px",
                fontSize: "0.875rem",
              }}
            >
              {myResponse.patientName == null ? "---" : myResponse.patientName}
            </h3>
            <h3
              style={{
                color: "#19b0e6",
                fontWeight: "800",
                marginBottom: "1px",
                fontSize: "0.875rem",
              }}
            >
              {myResponse.kitid == null ? "---" : myResponse.kitid}
            </h3>
            <h3
              style={{
                color: "#19b0e6",
                fontWeight: "800",
                marginBottom: "1px",
                fontSize: "0.875rem",
              }}
            >
              {myResponse.SampleDate == null ? "---" : myResponse.SampleDate}
            </h3>
          </div>

          <div
            style={{
              width: "93.5%",
              marginLeft: "25px",
              borderBottom: "1px solid grey",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          ></div>

          <div
            style={{
              display: "flex",
              marginTop: "1rem",
              marginBottom: "5rem",
              flexDirection: "column",
              flexGrow: 1,
              alignItems: "center",
            }}
          >
            <h2
              style={{
                color: "#19b0e6",
                fontWeight: "700",
                fontSize: "1.5rem",
              }}
            >
              YourGutMap IgG Food Sensitivity Screen
            </h2>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "1rem",
                width: "100%",
                marginTop: "1rem",
              }}
            >
              <div
                style={{
                  marginBottom: "1rem",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "white",
                  width: "15rem",
                  height: "2.5rem",
                  fontSize: "0.875rem",
                  borderRadius: "0.7rem",
                  backgroundColor: "#d13a3d",
                  paddingTop: "0.4rem",
                }}
              >
                {"Elevated > 10 IgG AU/ml"}
              </div>
              <div
                style={{
                  marginBottom: "0.5rem",
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "white",
                  width: "15rem",
                  height: "2.5rem",
                  fontSize: "0.875rem",
                  borderRadius: "0.7rem",
                  backgroundColor: "#ce9140",
                  paddingTop: "0.4rem",
                }}
              >
                {"Borderline 5 - 9 IgG AU/ml"}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  color: "white",
                  width: "15rem",
                  height: "2.5rem",
                  fontSize: "0.875rem",
                  borderRadius: "0.7rem",
                  backgroundColor: "#4380b6",
                  paddingTop: "0.4rem",
                }}
              >
                {"Normal < 5 IgG AU/ml"}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
                width: "100%",
                marginLeft: "4rem",
                marginTop: "1rem",
              }}
            >
              <h2
                style={{
                  color: "#19b0e6",
                  fontWeight: "700",
                  fontSize: "1.5rem",
                  marginTop: "0.0125rem",
                }}
              >
                {Object.keys(categories)[11]}
              </h2>
            </div>

            <div
              style={{
                marginLeft: "1.5rem",
                display: "flex",
                flexWrap: "wrap",
                gap: "0.83rem",
                marginTop: "0.5rem",
              }}
            >
              {/* /Dairy Product and Eggs/ */}
              {myResult.map((data, index) => (
                <>
                  {(categories[Object.keys(categories)[11]] || {}).includes(
                    getName(data.name)
                  )
                    ? Box(
                        data.value.toFixed(2),
                        getName(data.name),
                        getColor(data.value)
                      )
                    : null}
                </>
              ))}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              justifyContent: "flex-end",
              alignItems: "center",
              marginBottom: "2.5rem",
            }}
          >
            <img
              src="/logo.png"
              alt="logo2"
              style={{ width: "4rem", marginBottom: "0.5rem" }}
            />
            {/* <div style={{ width: '0.5rem' }}></div> */}
            <h1
              style={{ color: "#19b0e6", fontSize: "1rem", fontWeight: "700" }}
            >
              Food Sensitivity
            </h1>
          </div>
        </div>

        {/* page 3۔1.2 new*/}
        <div className="">
          {
            <div
              className="page1"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "297mm",
                width: "210mm",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                  marginTop: "0.75rem",
                }}
              >
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Patient Name
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample ID
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample Date
                </h3>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                }}
              >
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.patientName == null
                    ? "---"
                    : myResponse.patientName}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.kitid == null ? "---" : myResponse.kitid}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.SampleDate == null
                    ? "---"
                    : myResponse.SampleDate}
                </h3>
              </div>
              <div
                style={{
                  width: "93.5%",
                  marginLeft: "25px",
                  borderBottom: "1px solid grey",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                    marginLeft: "4rem",
                  }}
                >
                  <h2
                    style={{
                      color: "#19b0e6",
                      fontWeight: "700",
                      fontSize: "1.5rem",
                      marginTop: "0.0125rem",
                    }}
                  >
                    {Object.keys(categories)[4]}
                  </h2>
                </div>

                <div
                  style={{
                    marginLeft: "1.5rem",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "0.83rem",
                    marginTop: "0.5rem",
                  }}
                >
                  {myResult.map((data, index) => (
                    <>
                      {/* cereals and seeds */}
                      {(categories[Object.keys(categories)[4]] || {}).includes(
                        getName(data.name)
                      )
                        ? Box(
                            data.value.toFixed(2),
                            getName(data.name),
                            getColor(data.value)
                          )
                        : null}
                    </>
                  ))}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: "1.5rem",
                }}
              >
                <img
                  src="/logo.png"
                  alt="logo2"
                  style={{ width: "4rem", marginBottom: "0.5rem" }}
                />
                <h1
                  style={{
                    color: "#19b0e6",
                    fontSize: "1rem",
                    fontWeight: "700",
                  }}
                >
                  Food Sensitivity
                </h1>
              </div>
            </div>
          }
        </div>

        {/* page 3۔1.2*/}
        {
          <div className="">
            {
              <div
                className="page1"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  height: "297mm",
                  width: "210mm",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: "1.75rem",
                    paddingRight: "1.75rem",
                    marginTop: "0.75rem",
                  }}
                >
                  <h3
                    className="myblue"
                    style={{
                      fontWeight: "800",
                      marginBottom: "0.25rem",
                      fontSize: "1rem",
                    }}
                  >
                    Patient Name
                  </h3>
                  <h3
                    className="myblue"
                    style={{
                      fontWeight: "800",
                      marginBottom: "0.25rem",
                      fontSize: "1rem",
                    }}
                  >
                    Sample ID
                  </h3>
                  <h3
                    className="myblue"
                    style={{
                      fontWeight: "800",
                      marginBottom: "0.25rem",
                      fontSize: "1rem",
                    }}
                  >
                    Sample Date
                  </h3>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingLeft: "1.75rem",
                    paddingRight: "1.75rem",
                  }}
                >
                  <h3
                    style={{
                      color: "#19b0e6",
                      fontWeight: "800",
                      marginBottom: "1px",
                      fontSize: "0.875rem",
                    }}
                  >
                    {myResponse.patientName == null
                      ? "---"
                      : myResponse.patientName}
                  </h3>
                  <h3
                    style={{
                      color: "#19b0e6",
                      fontWeight: "800",
                      marginBottom: "1px",
                      fontSize: "0.875rem",
                    }}
                  >
                    {myResponse.kitid == null ? "---" : myResponse.kitid}
                  </h3>
                  <h3
                    style={{
                      color: "#19b0e6",
                      fontWeight: "800",
                      marginBottom: "1px",
                      fontSize: "0.875rem",
                    }}
                  >
                    {myResponse.SampleDate == null
                      ? "---"
                      : myResponse.SampleDate}
                  </h3>
                </div>

                {/* <hr style={{ marginBottom: '1.5rem', marginTop: '0.5rem' }} /> */}
                <div
                  style={{
                    width: "93.5%",
                    marginLeft: "25px",
                    borderBottom: "1px solid grey",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                ></div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "100%",
                      marginLeft: "4rem",
                      marginTop: "1rem",
                    }}
                  >
                    <h2
                      style={{
                        color: "#19b0e6",
                        fontWeight: "700",
                        fontSize: "1.5rem",
                        marginTop: "0.0125rem",
                      }}
                    >
                      {Object.keys(categories)[8]}
                    </h2>
                  </div>

                  <div
                    style={{
                      marginLeft: "1.5rem",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "0.83rem",
                      marginTop: "0.5rem",
                    }}
                  >
                    {myResult.map((data, index) => (
                      <>
                        {/* meat */}
                        {(
                          categories[Object.keys(categories)[8]] || {}
                        ).includes(getName(data.name))
                          ? Box(
                              data.value.toFixed(2),
                              getName(data.name),
                              getColor(data.value)
                            )
                          : null}
                      </>
                    ))}
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      width: "100%",
                      marginLeft: "4rem",
                    }}
                  >
                    <h2
                      style={{
                        color: "#19b0e6",
                        fontWeight: "700",
                        fontSize: "1.5rem",
                        marginTop: "1rem",
                      }}
                    >
                      {Object.keys(categories)[0]}
                    </h2>
                  </div>
                  <div
                    style={{
                      marginLeft: "1.5rem",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "0.83rem",
                      marginTop: "1rem",
                    }}
                  >
                    {/* fish and sea food start */}
                    {fishBoxes.slice(0, 18).map((data, index) => (
                      <div key={index}>
                        {Box(
                          data.value.toFixed(2),
                          getName(data.name),
                          getColor(data.value)
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    justifyContent: "flex-end",
                    alignItems: "center",
                    marginBottom: "2.5rem",
                  }}
                >
                  <img
                    src="/logo.png"
                    alt="logo2"
                    style={{ width: "4rem", marginBottom: "0.5rem" }}
                  />
                  {/* <div style={{ width: '0.5rem' }}></div> */}
                  <h1
                    style={{
                      color: "#19b0e6",
                      fontSize: "1rem",
                      fontWeight: "700",
                    }}
                  >
                    Food Sensitivity
                  </h1>
                </div>
              </div>
            }
          </div>
        }
        {/* page 3۔1.3*/}
        <div className="">
          {
            <div
              className="page1"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "297mm",
                width: "210mm",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                  marginTop: "0.75rem",
                }}
              >
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Patient Name
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample ID
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample Date
                </h3>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                }}
              >
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.patientName == null
                    ? "---"
                    : myResponse.patientName}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.kitid == null ? "---" : myResponse.kitid}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.SampleDate == null
                    ? "---"
                    : myResponse.SampleDate}
                </h3>
              </div>

              {/* <hr style={{ marginBottom: '1.5rem', marginTop: '0.5rem' }} /> */}
              <div
                style={{
                  width: "93.5%",
                  marginLeft: "25px",
                  borderBottom: "1px solid grey",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    width: "95%",
                    flexWrap: "wrap",
                    justifyContent: "flex-start",
                    marginLeft: "1rem",
                    gap: "0.83rem",
                    marginTop: "1.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  {fishBoxes.slice(18).map((data, index) => (
                    <div key={index}>
                      {/* fish and sea food end */}
                      {Box(
                        data.value.toFixed(2),
                        getName(data.name),
                        getColor(data.value)
                      )}
                    </div>
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                    marginLeft: "4rem",
                  }}
                >
                  <h2
                    style={{
                      color: "#19b0e6",
                      fontWeight: "700",
                      fontSize: "1.5rem",
                      marginTop: "0.0125rem",
                    }}
                  >
                    {Object.keys(categories)[7]}
                  </h2>
                </div>

                <div
                  style={{
                    marginLeft: "1.5rem",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "0.83rem",
                    marginTop: "1rem",
                  }}
                >
                  {/* vegetables start */}
                  {vegetableBoxes.slice(0, 18).map((data, index) => (
                    <div key={index}>
                      {Box(
                        data.value.toFixed(2),
                        getName(data.name),
                        getColor(data.value)
                      )}
                    </div>
                  ))}
                </div>
              </div>
              {/* code removed of others */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: "1.5rem",
                }}
              >
                <img
                  src="/logo.png"
                  alt="logo2"
                  style={{ width: "4rem", marginBottom: "0.5rem" }}
                />
                {/* <div style={{ width: '0.5rem' }}></div> */}
                <h1
                  style={{
                    color: "#19b0e6",
                    fontSize: "1rem",
                    fontWeight: "700",
                  }}
                >
                  Food Sensitivity
                </h1>
              </div>
            </div>
          }
        </div>
        {/* page 3۔1.4*/}
        {/* <div className="">
        {myResult.length >= 176 && (
          <div className='page1' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '297mm', width: '210mm', }}>

            <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '1.75rem', paddingRight: '1.75rem', marginTop: '0.75rem' }}>
              <h3 className='myblue' style={{ fontWeight: '800', marginBottom: '0.25rem', fontSize: '1rem' }}>Patient Name</h3>
              <h3 className='myblue' style={{ fontWeight: '800', marginBottom: '0.25rem', fontSize: '1rem' }}>Sample ID</h3>
              <h3 className='myblue' style={{ fontWeight: '800', marginBottom: '0.25rem', fontSize: '1rem' }}>Sample Date</h3>
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '1.75rem', paddingRight: '1.75rem' }}>
              <h3 style={{ color: '#19b0e6', fontWeight: '800', marginBottom: '1px', fontSize: '0.875rem' }}>
                {myResponse.patientName == null ? "---" : myResponse.patientName}
              </h3>
              <h3 style={{ color: '#19b0e6', fontWeight: '800', marginBottom: '1px', fontSize: '0.875rem' }}>
                {myResponse.kitid == null ? "---" : myResponse.kitid}
              </h3>
              <h3 style={{ color: '#19b0e6', fontWeight: '800', marginBottom: '1px', fontSize: '0.875rem' }}>
                {myResponse.SampleDate == null ? "---" : myResponse.SampleDate}
              </h3>
            </div>

            <div style={{ width: "93.5%", marginLeft: "25px", borderBottom: "1px solid grey", marginTop: "10px", marginBottom: "10px" }}></div>


            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, alignItems: 'center' }}>



            </div>

            <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, justifyContent: 'flex-end', alignItems: 'center', marginBottom: '1.5rem' }}>
              <img src="/logo.png" alt="logo2" style={{ width: '4rem', marginBottom: '0.5rem' }} />
             <h1 style={{ color: '#19b0e6', fontSize: '1rem', fontWeight: '700' }}>Food Sensitivity</h1>
            </div>

          </div>
        )}
      </div> */}

        {/* page 3۔1.6*/}
        <div className="">
          {
            <div
              className="page1"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "297mm",
                width: "210mm",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                  marginTop: "0.75rem",
                }}
              >
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Patient Name
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample ID
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample Date
                </h3>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                }}
              >
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.patientName == null
                    ? "---"
                    : myResponse.patientName}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.kitid == null ? "---" : myResponse.kitid}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.SampleDate == null
                    ? "---"
                    : myResponse.SampleDate}
                </h3>
              </div>

              <div
                style={{
                  width: "93.5%",
                  marginLeft: "25px",
                  borderBottom: "1px solid grey",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginLeft: "1.5rem",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "0.83rem",
                    marginTop: "1rem",
                  }}
                >
                  {/* vegetable end */}
                  {vegetableBoxes.slice(18).map((data, index) => (
                    <div key={index}>
                      {Box(
                        data.value.toFixed(2),
                        getName(data.name),
                        getColor(data.value)
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: "1.5rem",
                }}
              >
                <img
                  src="/logo.png"
                  alt="logo2"
                  style={{ width: "4rem", marginBottom: "0.5rem" }}
                />
                <h1
                  style={{
                    color: "#19b0e6",
                    fontSize: "1rem",
                    fontWeight: "700",
                  }}
                >
                  Food Sensitivity
                </h1>
              </div>
            </div>
          }
        </div>
        {/* page 3۔1.7*/}
        <div className="">
          {
            <div
              className="page1"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "297mm",
                width: "210mm",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                  marginTop: "0.75rem",
                }}
              >
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Patient Name
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample ID
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample Date
                </h3>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                }}
              >
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.patientName == null
                    ? "---"
                    : myResponse.patientName}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.kitid == null ? "---" : myResponse.kitid}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.SampleDate == null
                    ? "---"
                    : myResponse.SampleDate}
                </h3>
              </div>

              <div
                style={{
                  width: "93.5%",
                  marginLeft: "25px",
                  borderBottom: "1px solid grey",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                    marginLeft: "4rem",
                    marginTop: "1rem",
                  }}
                >
                  <h2
                    style={{
                      color: "#19b0e6",
                      fontWeight: "700",
                      fontSize: "1.5rem",
                      marginTop: "0.0125rem",
                    }}
                  >
                    {Object.keys(categories)[10]}
                  </h2>
                </div>

                <div
                  style={{
                    marginLeft: "1.5rem",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "0.83rem",
                    marginTop: "0.5rem",
                  }}
                >
                  {myResult.map((data, index) => (
                    <>
                      {/* edible mushroom */}
                      {(categories[Object.keys(categories)[10]] || {}).includes(
                        getName(data.name)
                      )
                        ? Box(
                            data.value.toFixed(2),
                            getName(data.name),
                            getColor(data.value)
                          )
                        : null}
                    </>
                  ))}
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                    marginLeft: "4rem",
                  }}
                >
                  <h2
                    style={{
                      color: "#19b0e6",
                      fontWeight: "700",
                      fontSize: "1.5rem",
                      marginTop: "0.5rem",
                    }}
                  >
                    {Object.keys(categories)[12]}
                  </h2>
                </div>

                <div
                  style={{
                    marginLeft: "1.5rem",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "0.83rem",
                    marginTop: "0.5rem",
                  }}
                >
                  {myResult.map((data, index) => (
                    <>
                      {/* Legumes */}
                      {(categories[Object.keys(categories)[12]] || {}).includes(
                        getName(data.name)
                      )
                        ? Box(
                            data.value.toFixed(2),
                            getName(data.name),
                            getColor(data.value)
                          )
                        : null}
                    </>
                  ))}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: "1.5rem",
                }}
              >
                <img
                  src="/logo.png"
                  alt="logo2"
                  style={{ width: "4rem", marginBottom: "0.5rem" }}
                />
                {/* <div style={{ width: '0.5rem' }}></div> */}
                <h1
                  style={{
                    color: "#19b0e6",
                    fontSize: "1rem",
                    fontWeight: "700",
                  }}
                >
                  Food Sensitivity
                </h1>
              </div>
            </div>
          }
        </div>

        {/* page 3۔1.8*/}
        <div className="">
          {
            <div
              className="page1"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "297mm",
                width: "210mm",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                  marginTop: "0.75rem",
                }}
              >
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Patient Name
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample ID
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample Date
                </h3>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                }}
              >
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.patientName == null
                    ? "---"
                    : myResponse.patientName}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.kitid == null ? "---" : myResponse.kitid}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.SampleDate == null
                    ? "---"
                    : myResponse.SampleDate}
                </h3>
              </div>

              {/* <hr style={{ marginBottom: '1.5rem', marginTop: '0.5rem' }} /> */}
              <div
                style={{
                  width: "93.5%",
                  marginLeft: "25px",
                  borderBottom: "1px solid grey",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                    marginLeft: "4rem",
                    marginTop: "0.5rem",
                  }}
                >
                  <h2
                    style={{
                      color: "#19b0e6",
                      fontWeight: "700",
                      fontSize: "1.5rem",
                      marginTop: "0.0125rem",
                    }}
                  >
                    {Object.keys(categories)[6]}
                  </h2>
                </div>

                <div
                  style={{
                    marginLeft: "1.5rem",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "0.83rem",
                    marginTop: "0.5rem",
                  }}
                >
                  {myResult.map((data, index) => (
                    <>
                      {/* fruits */}
                      {(categories[Object.keys(categories)[6]] || {}).includes(
                        getName(data.name)
                      )
                        ? Box(
                            data.value.toFixed(2),
                            getName(data.name),
                            getColor(data.value)
                          )
                        : null}
                    </>
                  ))}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: "1.5rem",
                }}
              >
                <img
                  src="/logo.png"
                  alt="logo2"
                  style={{ width: "4rem", marginBottom: "0.5rem" }}
                />
                {/* <div style={{ width: '0.5rem' }}></div> */}
                <h1
                  style={{
                    color: "#19b0e6",
                    fontSize: "1rem",
                    fontWeight: "700",
                  }}
                >
                  Food Sensitivity
                </h1>
              </div>
            </div>
          }
        </div>
        {/* page 3۔1.9*/}
        <div className="">
          {
            <div
              className="page1"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "297mm",
                width: "210mm",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                  marginTop: "0.75rem",
                }}
              >
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Patient Name
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample ID
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample Date
                </h3>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                }}
              >
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.patientName == null
                    ? "---"
                    : myResponse.patientName}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.kitid == null ? "---" : myResponse.kitid}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.SampleDate == null
                    ? "---"
                    : myResponse.SampleDate}
                </h3>
              </div>

              <div
                style={{
                  width: "93.5%",
                  marginLeft: "25px",
                  borderBottom: "1px solid grey",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                    marginLeft: "4rem",
                  }}
                >
                  {/* <h2 style={{ color: '#19b0e6', fontWeight: '700', fontSize: '1.5rem', marginTop: '0.25rem' }}>Grains & Staples containing Gluten</h2> */}
                  <h2
                    style={{
                      color: "#19b0e6",
                      fontWeight: "700",
                      fontSize: "1.5rem",
                      marginTop: "0.0125rem",
                    }}
                  >
                    {Object.keys(categories)[2]}
                  </h2>
                </div>

                <div
                  style={{
                    marginLeft: "1.5rem",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "0.83rem",
                    marginTop: "0.5rem",
                  }}
                >
                  {myResult.map((data, index) => (
                    <>
                      {/* {index < 52 ? Box(data.value,getName(data.name), "#4180b8") : null} */}
                      {/* nuts */}
                      {(categories[Object.keys(categories)[2]] || {}).includes(
                        getName(data.name)
                      )
                        ? Box(
                            data.value.toFixed(2),
                            getName(data.name),
                            getColor(data.value)
                          )
                        : null}
                    </>
                  ))}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: "1.5rem",
                }}
              >
                <img
                  src="/logo.png"
                  alt="logo2"
                  style={{ width: "4rem", marginBottom: "0.5rem" }}
                />
                <h1
                  style={{
                    color: "#19b0e6",
                    fontSize: "1rem",
                    fontWeight: "700",
                  }}
                >
                  Food Sensitivity
                </h1>
              </div>
            </div>
          }
        </div>

        {/* page 3۔1.10*/}
        <div className="">
          {
            <div
              className="page1"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "297mm",
                width: "210mm",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                  marginTop: "0.75rem",
                }}
              >
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Patient Name
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample ID
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample Date
                </h3>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                }}
              >
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.patientName == null
                    ? "---"
                    : myResponse.patientName}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.kitid == null ? "---" : myResponse.kitid}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.SampleDate == null
                    ? "---"
                    : myResponse.SampleDate}
                </h3>
              </div>

              <div
                style={{
                  width: "93.5%",
                  marginLeft: "25px",
                  borderBottom: "1px solid grey",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                    marginLeft: "4rem",
                  }}
                >
                  {/* <h2 style={{ color: '#19b0e6', fontWeight: '700', fontSize: '1.5rem', marginTop: '0.25rem' }}>Grains & Staples containing Gluten</h2> */}
                  <h2
                    style={{
                      color: "#19b0e6",
                      fontWeight: "700",
                      fontSize: "1.5rem",
                      marginTop: "0.0125rem",
                    }}
                  >
                    {Object.keys(categories)[5]}
                  </h2>
                </div>

                <div
                  style={{
                    marginLeft: "1.5rem",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "0.83rem",
                    marginTop: "0.5rem",
                  }}
                >
                  {myResult.map((data, index) => (
                    <>
                      {/* spices */}
                      {(categories[Object.keys(categories)[5]] || {}).includes(
                        getName(data.name)
                      )
                        ? Box(
                            data.value.toFixed(2),
                            getName(data.name),
                            getColor(data.value)
                          )
                        : null}
                    </>
                  ))}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: "1.5rem",
                }}
              >
                <img
                  src="/logo.png"
                  alt="logo2"
                  style={{ width: "4rem", marginBottom: "0.5rem" }}
                />
                <h1
                  style={{
                    color: "#19b0e6",
                    fontSize: "1rem",
                    fontWeight: "700",
                  }}
                >
                  Food Sensitivity
                </h1>
              </div>
            </div>
          }
        </div>

        {/* page 3۔1.11*/}
        <div className="">
          {
            <div
              className="page1"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "297mm",
                width: "210mm",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                  marginTop: "0.75rem",
                }}
              >
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Patient Name
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample ID
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample Date
                </h3>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                }}
              >
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.patientName == null
                    ? "---"
                    : myResponse.patientName}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.kitid == null ? "---" : myResponse.kitid}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.SampleDate == null
                    ? "---"
                    : myResponse.SampleDate}
                </h3>
              </div>
              <div
                style={{
                  width: "93.5%",
                  marginLeft: "25px",
                  borderBottom: "1px solid grey",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                    marginLeft: "4rem",
                  }}
                >
                  <h2
                    style={{
                      color: "#19b0e6",
                      fontWeight: "700",
                      fontSize: "1.5rem",
                      marginTop: "0.0125rem",
                    }}
                  >
                    {Object.keys(categories)[9]}
                  </h2>
                </div>

                <div
                  style={{
                    marginLeft: "1.5rem",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "0.83rem",
                    marginTop: "0.5rem",
                  }}
                >
                  {myResult.map((data, index) => (
                    <>
                      {/* Herbal Tees & Coffee */}
                      {(categories[Object.keys(categories)[9]] || {}).includes(
                        getName(data.name)
                      )
                        ? Box(
                            data.value.toFixed(2),
                            getName(data.name),
                            getColor(data.value)
                          )
                        : null}
                    </>
                  ))}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                    marginLeft: "4rem",
                  }}
                >
                  <h2
                    style={{
                      color: "#19b0e6",
                      fontWeight: "700",
                      fontSize: "1.5rem",
                      marginTop: "0.3rem",
                      marginBottom: "0.00125rem",
                    }}
                  >
                    {Object.keys(categories)[1]}
                  </h2>
                </div>

                <div
                  style={{
                    marginLeft: "1.5rem",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "0.83rem",
                    marginTop: "0.5rem",
                  }}
                >
                  {myResult.map((data, index) => (
                    <>
                      {/* Novel foods */}
                      {(categories[Object.keys(categories)[1]] || {}).includes(
                        getName(data.name)
                      )
                        ? Box(
                            data.value.toFixed(2),
                            getName(data.name),
                            getColor(data.value)
                          )
                        : null}
                    </>
                  ))}
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                    marginLeft: "4rem",
                    marginTop: "1rem",
                  }}
                >
                  <h2
                    style={{
                      color: "#19b0e6",
                      fontWeight: "700",
                      fontSize: "1.5rem",
                      marginTop: "0.0125rem",
                    }}
                  >
                    {Object.keys(categories)[13]}
                  </h2>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "0.83rem",
                    marginTop: "0.5rem",
                    justifyContent: "flex-start",
                    width: "94%",
                  }}
                >
                  {myResult.map((data, index) => (
                    <>
                      {/* CCD */}
                      {(categories[Object.keys(categories)[13]] || {}).includes(
                        getName(data.name)
                      )
                        ? Box(
                            data.value.toFixed(2),
                            getName(data.name),
                            getColor(data.value)
                          )
                        : null}
                    </>
                  ))}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: "1.5rem",
                }}
              >
                <img
                  src="/logo.png"
                  alt="logo2"
                  style={{ width: "4rem", marginBottom: "0.5rem" }}
                />
                <h1
                  style={{
                    color: "#19b0e6",
                    fontSize: "1rem",
                    fontWeight: "700",
                  }}
                >
                  Food Sensitivity
                </h1>
              </div>
            </div>
          }
        </div>
        {/* page 3۔1.12*/}
        <div className="">
          {myResult.length <= 296 && (
            <div
              className="page1"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "297mm",
                width: "210mm",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                  marginTop: "0.75rem",
                }}
              >
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Patient Name
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample ID
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample Date
                </h3>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                }}
              >
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.patientName == null
                    ? "---"
                    : myResponse.patientName}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.kitid == null ? "---" : myResponse.kitid}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.SampleDate == null
                    ? "---"
                    : myResponse.SampleDate}
                </h3>
              </div>

              <div
                style={{
                  width: "93.5%",
                  marginLeft: "25px",
                  borderBottom: "1px solid grey",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    width: "100%",
                    marginLeft: "2rem",
                    marginTop: "1rem",
                  }}
                >
                  {/* <h2 style={{ color: '#19b0e6', fontWeight: '700', fontSize: '1.5rem', marginTop: '0.25rem' }}>Grains & Staples containing Gluten</h2> */}
                  <h2
                    style={{
                      marginLeft: "0.5rem",
                      color: "#19b0e6",
                      fontWeight: "700",
                      fontSize: "1.5rem",
                      marginTop: "0.0125rem",
                    }}
                  >
                    {Object.keys(categories)[3]}
                  </h2>
                </div>

                <div
                  style={{
                    marginLeft: "1.5rem",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "0.83rem",
                    marginTop: "0.5rem",
                  }}
                >
                  {myResult.map((data, index) => (
                    <>
                      {/* Others*/}
                      {(categories[Object.keys(categories)[3]] || {}).includes(
                        getName(data.name)
                      )
                        ? Box(
                            data.value.toFixed(2),
                            getName(data.name),
                            getColor(data.value)
                          )
                        : null}
                    </>
                  ))}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: "1.5rem",
                }}
              >
                <img
                  src="/logo.png"
                  alt="logo2"
                  style={{ width: "4rem", marginBottom: "0.5rem" }}
                />
                <h1
                  style={{
                    color: "#19b0e6",
                    fontSize: "1rem",
                    fontWeight: "700",
                  }}
                >
                  Food Sensitivity
                </h1>
              </div>
            </div>
          )}
        </div>

        {/* page 4 */}

        <div className="">
          {redBoxes + yellowBoxes <= 33 && (
            <div
              className="page1"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "297mm",
                width: "210mm",
              }}
            >
              {/* <Header /> */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                  marginTop: "0.75rem",
                }}
              >
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Patient Name
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample ID
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample Date
                </h3>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                }}
              >
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.patientName == null
                    ? "---"
                    : myResponse.patientName}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.kitid == null ? "---" : myResponse.kitid}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.SampleDate == null
                    ? "---"
                    : myResponse.SampleDate}
                </h3>
              </div>

              {/* <hr style={{ marginBottom: '1.5rem', marginTop: '0.5rem' }} /> */}
              <div
                style={{
                  width: "93.5%",
                  marginLeft: "25px",
                  borderBottom: "1px solid grey",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  alignItems: "center",
                }}
              >
                <h2 style={{ fontWeight: "700", fontSize: "1.5rem" }}>
                  Your Reactive Foods & Drinks
                </h2>

                <div
                  style={{
                    display: "flex",
                    gap: "3rem",
                    justifyContent: "center",
                    width: "95%",
                    marginTop: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "0.5rem",
                      marginBottom: "1rem",
                      color: "white",
                      width: "100%",
                      height: "2.5rem",
                      fontSize: "0.875rem",
                      borderRadius: "1.5rem",
                      backgroundColor: "#d13a3d",
                    }}
                  >
                    {"Elevated > 10 IgG AU/ml"}
                  </div>
                </div>

                <div
                  style={{
                    marginLeft: "1.5rem",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "0.83rem",
                    marginTop: "1.25rem",
                  }}
                >
                  {myResult.map(
                    (data, index) =>
                      data.value > 10 &&
                      Box(data.value.toFixed(2), getName(data.name), "#d13a3d")
                  )}
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "3rem",
                    justifyContent: "center",
                    width: "95%",
                    marginTop: "2rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "0.5rem",
                      marginBottom: "1rem",
                      color: "white",
                      width: "100%",
                      height: "2.5rem",
                      fontSize: "0.875rem",
                      borderRadius: "1.5rem",
                      backgroundColor: "#ce9140",
                    }}
                  >
                    {"Borderline 5 - 9 IgG AU/ml"}
                  </div>
                </div>

                <div
                  style={{
                    marginLeft: "1.5rem",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "0.83rem",
                    marginTop: "1.25rem",
                  }}
                >
                  {myResult.map(
                    (data, index) =>
                      data.value >= 5 &&
                      data.value <= 9 &&
                      Box(data.value.toFixed(2), getName(data.name), "#ce9140")
                  )}
                </div>
              </div>

              {/* <Footer /> */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: "3.5rem",
                }}
              >
                <img
                  src="/logo.png"
                  alt="logo2"
                  style={{ width: "4rem", marginBottom: "0.75rem" }}
                />
                <div style={{ width: "0.5rem" }}></div>
                <h1
                  style={{
                    color: "#19b0e6",
                    fontSize: "1rem",
                    fontWeight: "700",
                  }}
                >
                  Food Sensitivity
                </h1>
              </div>
            </div>
          )}
        </div>

        {/* page 5 */}

        <div className="">
          {redBoxes + yellowBoxes > 33 && (
            <div
              className="page1"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "297mm",
                width: "210mm",
              }}
            >
              {/* <Header /> */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                  marginTop: "0.75rem",
                }}
              >
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Patient Name
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample ID
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample Date
                </h3>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                }}
              >
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.patientName == null
                    ? "---"
                    : myResponse.patientName}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.kitid == null ? "---" : myResponse.kitid}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.SampleDate == null
                    ? "---"
                    : myResponse.SampleDate}
                </h3>
              </div>

              {/* <hr style={{ marginBottom: '1.5rem', marginTop: '0.5rem' }} /> */}
              <div
                style={{
                  width: "93.5%",
                  marginLeft: "25px",
                  borderBottom: "1px solid grey",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  alignItems: "center",
                }}
              >
                <h2 style={{ fontWeight: "700", fontSize: "1.5rem" }}>
                  Your Reactive Foods & Drinks
                </h2>

                <div
                  style={{
                    display: "flex",
                    gap: "3rem",
                    justifyContent: "center",
                    width: "95%",
                    marginTop: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "0.5rem",
                      marginBottom: "1rem",
                      color: "white",
                      width: "100%",
                      height: "2.5rem",
                      fontSize: "0.875rem",
                      borderRadius: "1.5rem",
                      backgroundColor: "#d13a3d",
                    }}
                  >
                    {"Elevated > 10 IgG AU/ml"}
                  </div>
                </div>

                {/* <div style={{ display: 'flex', gap: '1rem', marginTop: '1.25rem' }}>
    {Box("25", "Baker's Yeast", "#d13a3d")}
    {Box("25", "Baker's Yeast", "#d13a3d")}
    {Box("25", "Baker's Yeast", "#d13a3d")}
    {Box("25", "Baker's Yeast", "#d13a3d")}
  </div> */}
                {/* <div style={{ marginLeft: '1.5rem', display: 'flex', flexWrap: 'wrap', gap: '0.83rem', marginTop: '1.25rem' }}>
    {myResult.map((data, index) => (
      
      <>{data.value > 10 && Box(Math.round(data.value),getName(data.name), "#d13a3d") }
       </>
    ))}
  </div> */}
                <div
                  style={{
                    marginLeft: "1.5rem",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "0.83rem",
                    marginTop: "1.25rem",
                  }}
                >
                  {myResult.map(
                    (data, index) =>
                      data.value > 10 &&
                      Box(data.value.toFixed(2), getName(data.name), "#d13a3d")
                  )}
                </div>

                {/* <div style={{ display: 'flex', gap: '3rem', justifyContent: 'center', width: '95%', marginTop: '2rem' }}>
    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: "0.5rem", marginBottom: '1rem', color: 'white', width: '100%', height: '2.5rem', fontSize: '0.875rem', borderRadius: '1.5rem', backgroundColor: '#ce9140' }}>
      {"Borderline 5 - 9 IgG AU/ml"}
    </div>
  </div>
 */}

                {/* <div style={{ marginLeft: '1.5rem', display: 'flex', flexWrap: 'wrap', gap: '0.83rem', marginTop: '1.25rem' }}>
    {myResult.map((data, index) => (
      (data.value >= 5 && data.value <= 9) &&
      Box(data.value.toFixed(2), getName(data.name), "#ce9140")
    ))}
  </div> */}
              </div>

              {/* <Footer /> */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: "3.5rem",
                }}
              >
                <img
                  src="/logo.png"
                  alt="logo2"
                  style={{ width: "4rem", marginBottom: "0.75rem" }}
                />
                <div style={{ width: "0.5rem" }}></div>
                <h1
                  style={{
                    color: "#19b0e6",
                    fontSize: "1rem",
                    fontWeight: "700",
                  }}
                >
                  Food Sensitivity
                </h1>
              </div>
            </div>
          )}
        </div>

        {/* page 6 */}

        <div className="">
          {redBoxes + yellowBoxes > 33 && (
            <div
              className="page1"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "297mm",
                width: "210mm",
              }}
            >
              {/* <Header /> */}

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                  marginTop: "0.75rem",
                }}
              >
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Patient Name
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample ID
                </h3>
                <h3
                  className="myblue"
                  style={{
                    fontWeight: "800",
                    marginBottom: "0.25rem",
                    fontSize: "1rem",
                  }}
                >
                  Sample Date
                </h3>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingLeft: "1.75rem",
                  paddingRight: "1.75rem",
                }}
              >
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.patientName == null
                    ? "---"
                    : myResponse.patientName}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.kitid == null ? "---" : myResponse.kitid}
                </h3>
                <h3
                  style={{
                    color: "#19b0e6",
                    fontWeight: "800",
                    marginBottom: "1px",
                    fontSize: "0.875rem",
                  }}
                >
                  {myResponse.SampleDate == null
                    ? "---"
                    : myResponse.SampleDate}
                </h3>
              </div>

              {/* <hr style={{ marginBottom: '1.5rem', marginTop: '0.5rem' }} /> */}
              <div
                style={{
                  width: "93.5%",
                  marginLeft: "25px",
                  borderBottom: "1px solid grey",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              ></div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  alignItems: "center",
                }}
              >
                <h2 style={{ fontWeight: "700", fontSize: "1.5rem" }}>
                  Your Reactive Foods & Drinks
                </h2>

                {/* <div style={{ display: 'flex', gap: '3rem', justifyContent: 'center', width: '95%', marginTop: '0.5rem' }}>
    <div style={{ display: 'flex', justifyContent: 'center', paddingTop: "0.5rem", marginBottom: '1rem', color: 'white', width: '100%', height: '2.5rem', fontSize: '0.875rem', borderRadius: '1.5rem', backgroundColor: '#d13a3d' }}>
      {"Elevated > 10 IgG AU/ml"}
    </div>
  </div> */}

                {/* <div style={{ display: 'flex', gap: '1rem', marginTop: '1.25rem' }}>
    {Box("25", "Baker's Yeast", "#d13a3d")}
    {Box("25", "Baker's Yeast", "#d13a3d")}
    {Box("25", "Baker's Yeast", "#d13a3d")}
    {Box("25", "Baker's Yeast", "#d13a3d")}
  </div> */}
                {/* <div style={{ marginLeft: '1.5rem', display: 'flex', flexWrap: 'wrap', gap: '0.83rem', marginTop: '1.25rem' }}>
    {myResult.map((data, index) => (
      
      <>{data.value > 10 && Box(Math.round(data.value),getName(data.name), "#d13a3d") }
       </>
    ))}
  </div> */}
                {/* <div style={{ marginLeft: '1.5rem', display: 'flex', flexWrap: 'wrap', gap: '0.83rem', marginTop: '1.25rem' }}>
    {myResult.map((data, index) => (
      (data.value > 10) &&
      Box(data.value.toFixed(2), getName(data.name), "#d13a3d")
    ))}
  </div> */}

                <div
                  style={{
                    display: "flex",
                    gap: "3rem",
                    justifyContent: "center",
                    width: "95%",
                    marginTop: "0.7rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      paddingTop: "0.5rem",
                      marginBottom: "1rem",
                      color: "white",
                      width: "100%",
                      height: "2.5rem",
                      fontSize: "0.875rem",
                      borderRadius: "1.5rem",
                      backgroundColor: "#ce9140",
                    }}
                  >
                    {"Borderline 5 - 9 IgG AU/ml"}
                  </div>
                </div>

                <div
                  style={{
                    marginLeft: "1.5rem",
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "0.83rem",
                    marginTop: "1.25rem",
                  }}
                >
                  {myResult.map(
                    (data, index) =>
                      data.value >= 5 &&
                      data.value <= 9 &&
                      Box(data.value.toFixed(2), getName(data.name), "#ce9140")
                  )}
                </div>
              </div>

              {/* <Footer /> */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                  alignItems: "center",
                  marginBottom: "3.5rem",
                }}
              >
                <img
                  src="/logo.png"
                  alt="logo2"
                  style={{ width: "4rem", marginBottom: "0.75rem" }}
                />
                <div style={{ width: "0.5rem" }}></div>
                <h1
                  style={{
                    color: "#19b0e6",
                    fontSize: "1rem",
                    fontWeight: "700",
                  }}
                >
                  Food Sensitivity
                </h1>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Page;
